<template>
  <input
    type="text"
    class="inp-currency"
    :readonly="invoiceStyle"
    :class="[
      align == 'right' ? 'textRight' : align == 'center' ? 'textCenter' : '',
      prefix ? 'borderLeft' : 'borderRight',
      invoiceStyle ? 'invoiceStyle' : '',
      table ? 'tableStyle' : '',
    ]"
    v-model="displayValue"
    @focus="focusInput($event)"
    @click="selectInput($event)"
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    prefix: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: "right",
    },
    decima: {
      type: Number,
      default: 0,
    },
    sign: {
      type: String,
      default: "comma",
    },
    invoiceStyle: {
      type: Boolean,
      default: false,
    },
    table: {
      type: Boolean,
      default: false,
    },
    negative: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isInputActive: false,
    };
  },
  methods: {
    selectInput(event) {
      event.target.select();
    },

    focusInput(event) {
      this.isInputActive = true;
      setTimeout(() => {
        event.target.select();
      }, 1);
    },
  },

  computed: {
    displayValue: {
      get: function() {
        if (!this.modelValue) return 0;
        let currency = new Intl.NumberFormat('de-DE')
        const value = currency.format(this.modelValue)

        this.$emit("inputChange", value.replace(/[^\d,-]/g, '').replace(',', '.'));
        return value;
      },
      set: function (modifiedValue) {
        this.$emit("inputChange", modifiedValue.replace(/[^\d,-]/g, '').replace(',', '.'));
      },

      // Old function
      // get: function () {
      //   if (!this.modelValue) {
      //     return 0;
      //   }
      //   let value = this.modelValue ? this.modelValue : "0";
      //   this.negative
      //     ? (value = value.toString().replace(/[^\d.]/g, ""))
      //     : (value = value.toString().replace(/[^-\d.]/g, ""));
      //   value = value
      //     .toString()
      //     .replace(
      //       /(-?\d)(?=(\d{3})+(?:\.\d+)?$)/g,
      //       this.sign == "dot" ? "$1," : "$1."
      //     );
      //   if (this.sign == "comma" && this.decima > 1) {
      //     let latDot = value.lastIndexOf(".");
      //     value =
      //       value.substr(0, latDot) +
      //       "," +
      //       value.substr(latDot + 1, value.length);
      //   }
      //   let lastInvalidZeroDigitIndex = value.search(/[^,0]/);
      //   value = value.substr(lastInvalidZeroDigitIndex);

      //   let fullOfZero = value.search(/^[^\d,]+$/);
      //   if (fullOfZero != -1) {
      //     value = "0";
      //   }

      //   if (!this.prefix) {
      //     this.$emit("inputChange", value.replace(/\D/g, ""));

      //     return value;
      //   } else {
      //     return "₫ " + value;
      //   }
      // },
      // set: function (modifiedValue) {
      //   this.$emit("inputChange", modifiedValue);
      // },
    },
  },
};
</script>

<style scoped>
.inp-currency {
  flex-shrink: 1;
  flex-grow: 1;
  line-height: 1.5;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  flex: 1 1 0%;
  width: 100%;
  border: 1px solid #dcdfe5;
  margin-bottom: 0;
  border-radius: 0.25rem;
  font-weight: bold;
  height: 28px;
  font-size: 0.9rem;
}
.textRight {
  text-align: right;
}
.textCenter {
  text-align: center;
}
input:focus {
  border: 1px solid #409eff;
  outline: none;
}
.invoiceStyle {
  line-height: 0;
  border: none;
  padding: 0;
  color: black;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0 16px;
  cursor: default;
}
.invoiceStyle:focus {
  border: none;
}
.tableStyle {
  padding: 0 !important;
  background: none !important;
  font-weight: 400;
}
</style>
