<div class="mx-auto relative border-2 bg-white max-w-7xl p-4">
  <div class="header">
    <h5 class="text-center font-bold">
      CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
      <p class="underline">Độc lập - Tự do - Hạnh phúc</p>
    </h5>
    <div class="titleTable text-center mt-2">
      <p class="font-bold">TỜ KHAI THUẾ GIÁ TRỊ GIA TĂNG</p>
    </div>
    <p class="text-center font-normal text-sm">
      <p class="text-sm italic">(Áp dụng đối với người nộp thuế tính thuế theo phương pháp khấu trừ có hoạt động sản xuất kinh doanh)</p>
      <p class="text-base"><span class="font-bold">[01a]</span> Tên hoạt động sản xuất kinh doanh: {{dataTableTaxReturn.ct01a}}</p>
      <p class="text-base"><span class="font-bold">[01b]</span> Kỳ tính thuế: {{dataTableTaxReturn.ct01b}}</p>
    </p>
  </div>

  <div class="absolute top-5 right-5">
    <div class="border-2 text-center border-black p-2 w-48">
      <p>Mẫu số: <span class="font-bold">01/GTGT</span></p>
      <p class="text-xs italic">
        (Ban hành kèm theo Thông tư số 80/2021/TT-BTC ngày 29/09/2021 của Bộ Tài chính)
      </p>
    </div>
  </div>

  <div class="hscroll mx-16">
    <div class="w-full">
      <table class="w-full leading-7">
        <tr>
          <td class="text-center w-1/2"><span class="font-bold">[02]</span> Lần đầu: □ </td>
          <td class="text-center"><span class="font-bold">[03]</span> Bổ sung lần thứ: □</td>
        </tr>

        <!-- 04 -->
        <tr>
          <td colspan="2" class="font-bold"><span class="font-bold">[04]</span> Tên người nộp thuế: {{ dataTableTaxReturn.ct04 }}</td>
        </tr>

        <!-- 05 -->
        <tr>
          <td>
            <div class="flex justify-between ">
              <div><span class="font-bold">[05]</span> Mã số thuế:</div>
              <div class="border border-black px-4 text-center w-56">
                {{dataTableTaxReturn.ct05}}
              </div>
            </div>
          </td>
        </tr>

        <!-- 06 -->
        <tr> <td colspan="2" class="font-bold"><span >[06]</span> Tên đại lý thuế (nếu có): {{ dataTableTaxReturn.ct06 }}</td> </tr>

        <!-- 07 -->
        <tr>
          <td>
            <div class="flex justify-between">
              <div><span class="font-bold">[07]</span> Mã số thuế:</div>
              <div class="border border-black px-4 text-center w-56">{{ dataTableTaxReturn.ct07 }}</div>
            </div>
          </td>
          <td></td>
        </tr>

        <!-- 08 -->
        <tr>
          <td><span class="font-bold">[08]</span> Hợp đồng đại lý thuế: Số {{ dataTableTaxReturn.ct08 }}</td>
          <td>Ngày</td>
        </tr>

        <!-- 09 -->
        <tr> <td colspan="02"><span class="font-bold">[09]</span> Tên đơn vị phụ thuộc/địa điểm kinh doanh của hoạt động sản xuất kinh doanh khác tỉnh nơi đóng trụ sở chính:{{ dataTableTaxReturn.ct09 }}</td> </tr>

        <!-- 10 -->
        <tr> <td colspan="02"><span class="font-bold">[10]</span> Mã số thuế đơn vị phụ thuộc/Mã số địa điểm kinh doanh:{{ dataTableTaxReturn.ct10 }}</td> </tr>

        <!-- 11 -->
        <tr> <td colspan="02"><span class="font-bold">[11]</span> Địa chỉ nơi có hoạt động sản xuất kinh doanh khác tỉnh nơi đóng trụ sở chính:</td> </tr>
        <tr class="w-full">
          <td colspan="02" class="flex justify-between w-full">
            <div><span class="font-bold">[11a]</span> Phường/Xã: {{ dataTableTaxReturn.ct11a }}</div>
            <div><span class="font-bold">[11b]</span> Quận/Huyện: {{ dataTableTaxReturn.ct11b }}</div>
            <div><span class="font-bold">[11c]</span> Tỉnh/Thành Phố: {{ dataTableTaxReturn.ct11c }}</div>
          </td>
        </tr>

      </table>
    </div>
  </div>

  <div class="mt-0 hscroll table-border vat-report-table">
    <span class="float-right italic">Đơn vị tiền: Đồng Việt Nam</span>
    <table class="table-auto border w-full">
      <thead>
        <tr>
          <th>STT</th>
          <th>Chỉ tiêu</th>
          <th class="w-52">Giá trị HHDV (chưa có thuế GTGT)</th>
          <th class="w-52">Thuế GTGT</th>
        </tr>
      </thead>

      <tbody class="font-bold bgColorTitle">
        <tr>
          <td class="text-center">A</td>
          <td>
            <div class="flex justify-between">
              <div>
                Không phát sinh hoạt động mua, bán trong kỳ (đánh dấu "X"):
              </div>
              <div>[21]</div>
            </div>
          </td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td class="text-center">B</td>
          <td colspan="2">Thuế GTGT còn được khấu trừ kỳ trước chuyển sang</td>
          <td>
            <div class="flex justify-between">
              <div>[22]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct22"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-center">C</td>
          <td colspan="3">Kê khai thuế GTGT phải nộp Ngân sách nhà nước</td>
        </tr>
      </tbody>

      <tbody>
        <tr class="font-bold bgColorTitle">
          <td class="text-center">I</td>
          <td colspan="3">Hàng hoá, dịch vụ (HHDV) mua vào trong kỳ</td>
        </tr>
        <tr class="font-bold">
          <td class="text-center">1</td>
          <td>Giá trị và thuế GTGT của hàng hoá, dịch vụ mua vào</td>
          <td>
            <div class="flex justify-between items-center">
              <div>[23]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct23"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="flex justify-between items-center">
              <div>[24]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct24"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>


        <tr class="font-bold">
          <td class="text-center"></td>
          <td>Trong đó: hàng hóa, dịch vụ nhập khẩu</td>
          <td>
            <div class="flex justify-between items-center">
              <div>[23a]</div>
              <div class="font-normal">
                <input-money
                  v-model="dataTableTaxReturn.ct23a"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="flex justify-between items-center">
              <div>[24a]</div>
              <div class="font-normal">
                <input-money
                  v-model="dataTableTaxReturn.ct24a"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>


        <tr class="font-bold">
          <td class="text-center">2</td>
          <td colspan="2">Tổng số thuế GTGT được khấu trừ kỳ này</td>
          <td>
            <div class="flex justify-between">
              <div>[25]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct25"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>

      <tbody>
        <tr class="font-bold bgColorTitle">
          <td class="text-center">II</td>
          <td colspan="3">Hàng hoá, dịch vụ bán ra trong kỳ</td>
        </tr>

        <tr class="font-bold">
          <td class="text-center">1</td>
          <td>Hàng hóa, dịch vụ bán ra không chịu thuế GTGT</td>
          <td>
            <div class="flex justify-between">
              <div>[26]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct26"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
          <td></td>
        </tr>

        <tr class="font-bold">
          <td class="text-center">2</td>
          <td>
            Hàng hóa, dịch vụ bán ra chịu thuế GTGT ([27]= [29]+[30]+[32]+[32a]; [28]=
            [31]+[33])
          </td>
          <td>
            <div class="flex justify-between">
              <div>[27]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct27"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="flex justify-between">
              <div>[28]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct28"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-center">a</td>
          <td>Hàng hoá, dịch vụ bán ra chịu thuế suất 0%</td>
          <td>
            <div class="flex justify-between">
              <div class="font-bold">[29]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct29"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
          <td></td>
        </tr>

        <tr>
          <td class="text-center">b</td>
          <td>Hàng hoá, dịch vụ bán ra chịu thuế suất 5%</td>
          <td>
            <div class="flex justify-between">
              <div class="font-bold">[30]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct30"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="flex justify-between">
              <div class="font-bold">[31]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct31"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-center">c</td>
          <td>Hàng hoá, dịch vụ bán ra chịu thuế suất 10%</td>
          <td>
            <div class="flex justify-between">
              <div class="font-bold">[32]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct32"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="flex justify-between">
              <div class="font-bold">[33]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct33"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-center">d</td>
          <td>Hàng hoá, dịch vụ bán ra không tính thuế</td>
          <td>
            <div class="flex justify-between">
              <div class="font-bold">[32a]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct32a"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
          <td></td>
        </tr>

        <tr>
          <td class="text-center">3</td>
          <td>
            Tổng doanh thu và thuế GTGT của HHDV bán ra ([34] = [26] + [27];
            [35] = [28])
          </td>
          <td>
            <div class="flex justify-between">
              <div class="font-bold">[34]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct34"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="flex justify-between">
              <div class="font-bold">[35]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct35"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>

      <tbody>
        <tr class="font-bold bgColorTitle">
          <td class="text-center">III</td>
          <td colspan="2">Thuế GTGT phát sinh trong kỳ ([36] = [35] - [25])</td>
          <td>
            <div class="flex justify-between">
              <div>[36]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct36"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>

      <tbody>
        <tr class="font-bold bgColorTitle">
          <td class="text-center">IV</td>
          <td colspan="3">Điều chỉnh tăng, giảm thuế GTGT của các kỳ trước</td>
        </tr>

        <tr class="font-bold">
          <td class="text-center">1</td>
          <td colspan="2">Điều chỉnh tăng</td>
          <td>
            <div class="flex justify-between">
              <div>[37]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct37"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>

        <tr class="font-bold">
          <td class="text-center">2</td>
          <td colspan="2">Điều chỉnh giảm</td>
          <td>
            <div class="flex justify-between">
              <div>[38]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct38"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>

      <tbody>
        <tr class="font-bold bgColorTitle">
          <td class="text-center">V</td>
          <td colspan="2">Thuế giá trị gia tăng nhận bàn giao được khấu trừ trong kỳ</td>
          <td>
            <div class="flex justify-between">
              <div>[39a]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct39a"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>

      <tbody>
        <tr class="font-bold bgColorTitle">
          <td class="text-center">VI</td>
          <td colspan="3">Xác định nghĩa vụ thuế GTGT phải nộp trong kỳ:</td>
        </tr>

        <tr class="font-bold">
          <td class="text-center">1</td>
          <td colspan="2">
            Thuế GTGT phải nộp của hoạt động sản xuất kinh doanh trong kỳ<br>
            {[40a]=([36]-[22]+[37]-[38]-[39a]) ≥ 0}
          </td>
          <td>
            <div class="flex justify-between">
              <div>[40a]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct40a"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>

        <tr class="font-bold">
          <td class="text-center">2</td>
          <td colspan="2">
            Thuế giá trị gia tăng mua vào của dự án đầu tư được bù trừ với thuế GTGT còn phải nộp của hoạt động sản xuất kinh doanh cùng kỳ tính thuế ([40b]≤[40a])
          </td>
          <td>
            <div class="flex justify-between">
              <div>[40b]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct40b"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>

        <tr class="font-bold">
          <td class="text-center">3</td>
          <td colspan="2">
            Thuế GTGT còn phải nộp trong kỳ ([40] = [40a] - [40b])
          </td>
          <td>
            <div class="flex justify-between">
              <div>[40]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct40"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>

        <tr class="font-bold">
          <td class="text-center">4</td>
          <td colspan="2">
            Thuế GTGT chưa khấu trừ hết kỳ này {[41]=([36]-[22]+[37]-[38]-[39a]) ≤ 0}
          </td>
          <td>
            <div class="flex justify-between">
              <div>[41]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct41"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>

        <tr class="font-bold">
          <td class="text-center font-normal">4.1</td>
          <td colspan="2">Thuế GTGT đề nghị hoàn ([42] ≤ [41])</td>
          <td>
            <div class="flex justify-between">
              <div>[42]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct42"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>

        <tr class="font-bold">
          <td class="text-center font-normal">4.2</td>
          <td colspan="2">
            Thuế GTGT còn được khấu trừ chuyển kỳ sau ([43] = [41] - [42])
          </td>
          <td>
            <div class="flex justify-between">
              <div>[43]</div>
              <div class="font-normal">
                <input-money
                  v-model.lazy="dataTableTaxReturn.ct43"
                  class="money"
                  :invoiceStyle="true"
                  :table="true"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="container p-10">
    <p class="font-bold mt-2">
      Tôi cam đoan số liệu khai trên là đúng và chịu trách nhiệm trước pháp luật
      về những số liệu đã khai./.
    </p>

    <div class="grid grid-cols-2 gap-4 mt-10 mb-40">
      <div class="text-center">
        <h5 class="font-bold">NHÂN VIÊN ĐẠI LÝ THUẾ</h5>
        <div class="text-left mt-3">
          <p>Họ và tên:............................</p>
          <p>Chứng chỉ hành nghề số:..........................</p>
        </div>
      </div>

      <div class="text-center mt-0">
        <div class="pl-36 text-center">
          <div class="date text-right">
            ...............,ngày..........tháng..........năm..........
          </div>
          <h5 class="mt-3">
            <span class="uppercase font-bold">Người nộp thuế </span>hoặc
            <br />
            <span class="uppercase font-bold">
              ĐẠI DIỆN HỢP PHÁP CỦA NGƯỜI NỘP THUẾ
            </span>
            <br />
            <span>Ký tên, đóng dấu (ghi rõ họ tên và chức vụ)</span>
          </h5>
        </div>
      </div>
    </div>
      
    </div>
  </div>
</div>
